/**
 * 正则验证
 * 大写字母开头  驼峰命名法
 */

//去除前后空格
export const Trim = (str) => {
    return str.replace(/(^\s*)|(\s*$)/g, "")
}

//验证电话号码的正确性
export const VaildPhoneNumber = (phone) => {
    return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(phone)
}

//验证邮箱的正确性
export  const VaildEmail = (email) => {
    return /^([a-zA-Z\d])((\w|-)+\.?)+@([a-zA-Z\d]+\.)+[a-zA-Z]{2,6}$/.test(email)
}

//验证身份证号码的正确性
export  const VaildCard = (card) => {
    return /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(card)
}