//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vcode from 'vue-puzzle-vcode';
import { Trim } from '../../utils/regular';
import { title } from '@/config';
export default {
    data() {
        return {
            title,
            username: '',
            password: '',
            isShow: false,
            imgList: [
                require('../../assets/images/verify-imgs/verify-img1.jpg'),
                require('../../assets/images/verify-imgs/verify-img2.jpg'),
                require('../../assets/images/verify-imgs/verify-img3.jpg'),
                // require('../../assets/images/verify-imgs/verify-img4.jpg'),
                // require('../../assets/images/verify-imgs/verify-img5.jpg'),
                // require('../../assets/images/verify-imgs/verify-img6.jpg'),
            ],
            firstMenuUrl: '',
            loginContentHouseOve: true,
        };
    },
    inject: ['hideLoading', 'showLoading'],
    components: {
        Vcode,
    },
    mounted() {
        this.hideLoading();
    },
    methods: {
        handlerLoginContentHouseOver() {
            this.loginContentHouseOve = false;
        },
        showVerify() {
            let username = Trim(this.username);
            let password = Trim(this.password);
            if (username === '') {
                return this.$notify({
                    type: 'error',
                    title: '提示',
                    message: '请输入用户名',
                    duration: 2000,
                    position: 'bottom-right',
                });
            }

            if (password === '') {
                return this.$notify({
                    type: 'error',
                    title: '提示',
                    message: '请输入密码',
                    duration: 2000,
                    position: 'bottom-right',
                });
            }
            // 把页面滚动到最顶部，再显示验证码弹窗
            window.scrollTo(0, 0);
            this.isShow = true;
        },
        closeVerify() {
            this.isShow = false;
        },
        // 登录
        submit() {
            this.showLoading();
            this.$store
                .dispatch('login/userLogin', {
                    username: this.username,
                    password: this.password,
                })
                .then(async res => {
                    this.hideLoading();
                    this.$notify({
                        type: res.code === 200 ? 'success' : 'error',
                        title: '提示',
                        message:
                            res.code === 200
                                ? '登录成功,欢迎您回来!'
                                : res.msg,
                        duration: 2000,
                        position: 'bottom-right',
                    });
                    if (res.code === 200) {
                        // 存储token
                        this.$ls.set('JWT_TOKEN', res.data);

                        // 获取用户信息
                        this.$store.dispatch('login/getUserInfo').then(res => {
                            let userInfo = res.data;
                            if (userInfo.menuList.length > 0) {
                                this.recursionMenu(userInfo.menuList[0]);
                                if (this.firstMenuUrl) {
                                    this.$router.push(this.firstMenuUrl);
                                } else {
                                    this.$notify({
                                        type: 'error',
                                        title: '提示',
                                        message: '请联系管理员分配菜单!',
                                        duration: 2000,
                                        position: 'bottom-right',
                                    });
                                }
                            } else {
                                this.$notify({
                                    type: 'error',
                                    title: '提示',
                                    message: '请联系管理员分配菜单!',
                                    duration: 2000,
                                    position: 'bottom-right',
                                });
                            }
                        });

                        // 获取用户菜单， 跳转到第一个菜单
                        /* this.$store.dispatch('menu/getMenuList').then(menuRes => {
                            if (menuRes.length > 0) {
                                this.recursionMenu(menuRes[0]);
                                if (this.firstMenuUrl) {
                                    this.$router.push(this.firstMenuUrl);
                                } else {
                                    this.$notify({
                                        type: 'error',
                                        title: '提示',
                                        message: '请联系管理员分配菜单!',
                                        duration: 2000,
                                        position: 'bottom-right',
                                    });
                                }
                            } else {
                                this.$notify({
                                    type: 'error',
                                    title: '提示',
                                    message: '请联系管理员分配菜单!',
                                    duration: 2000,
                                    position: 'bottom-right',
                                });
                            }
                        }); */
                    }
                });
        },

        // 递归找出当前用户的第一个菜单
        recursionMenu(itemObject) {
            if (itemObject.children && itemObject.children.length > 0) {
                this.recursionMenu(itemObject.children[0]);
            } else {
                // 菜单的路由上有 url = menu, 和 pagePath = system/menu
                this.firstMenuUrl = '/' + itemObject.pagePath;
            }
        },

        //验证成功
        VcodePass() {
            this.isShow = false;
            this.submit();
        },
    },
};
